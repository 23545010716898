import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons'

import Button from '../Button/Button'

import inpowerCardImage from '../../images/inpower_card.png'
import inPowerLogoReverse from '../../images/inPOWER_reverse.svg'

import styles from './footer.module.scss'

export default function Footer(props) {
  const {
    data: {
      contentfulPayBillsPage: pb,
      contentfulDirectDepositPage: dd,
      contentfulSocialLinks: { links },
      site: {
        siteMetadata: { payomaticLocationsLink, appLink }
      }
    }
  } = props

  return (
    <Fragment>
      <footer className={styles.footer}>
        <div className={styles.footerInner}>
          <img
            className={styles.footerCard}
            src={inpowerCardImage}
            alt="inPower Card"
          />
          <Link to="/">
            <img
              className={styles.inPowerLogo}
              src={inPowerLogoReverse}
              alt="inPower Card"
            />
          </Link>
          <nav className={styles.footerNav}>
            <h3 className={styles.footerTitle}>Pages</h3>
            <a className={styles.footerNavLink} href={appLink}>
              Download Mobile App
            </a>
            <Link className={styles.footerNavLink} to={`/${dd.fields.slug}`}>
              {dd.fields.navLabel}
            </Link>
            <Link className={styles.footerNavLink} to={`/${pb.fields.slug}`}>
              {pb.fields.navLabel}
            </Link>
            {/* <Link className={styles.footerNavLink} to="/blog">
              Blog
            </Link> */}
            {/* <a
              className={styles.footerNavLink}
              href={payomaticLocationsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Find a store
            </a> */}
            <a
              className={styles.footerNavLink}
              href={payomaticLocationsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started
            </a>
            {/* <Link className={styles.footerNavLink} to="/spanish">
              Translate site to Spanish
            </Link> */}
          </nav>
          <nav className={styles.socialNav}>
            <h3 className={styles.footerTitle}>Follow Us On</h3>
            {links.map(link => (
              <SocialIcon color="#000" key={link} url={link} />
            ))}
          </nav>
          <div className={styles.legal}>
            <p>
              <sup>1</sup> Terms and conditions apply, see&nbsp;
              <a href="/assets/cardholder-agreement.pdf" target="_blank">
                Cardholder Agreement
              </a>{' '}
              for details. Registration and identity verification required to
              access all ınPOWER features and benefits.
            </p>
            <p>
              <sup>2</sup> IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A
              NEW CARD ACCOUNT: To help the government fight the funding of
              terrorism and money laundering activities, federal law requires
              all financial institutions to obtain, verify, and record
              information that identifies each person who opens a Card Account.
              WHAT THIS MEANS FOR YOU: When you open a Card Account, we will ask
              for your name, address, date of birth, and other information that
              will allow us to identify you. We may also ask to see a copy of
              your driver’s license or other identifying documents.
            </p>
            <p>
              <sup>3</sup> Standard text messaging and/or data rates from your
              wireless service may apply.
            </p>
            <p>
              <sup>4</sup> Faster access to funds is based on comparison of
              traditional banking policies and deposit of paper checks from
              employers and government agencies versus deposits made
              electronically. Direct Deposit and earlier availability of funds
              is subject to payer’s support of the feature and timing of payer’s
              funding.
            </p>
            <p>
              <sup>5</sup> Conditions and exceptions apply – see&nbsp;
              <a href="/assets/cardholder-agreement.pdf" target="_blank">
                Cardholder Agreement
              </a>
              .
            </p>
            <p>
              The inPOWER Prepaid Mastercard is issued by The Bancorp Bank;
              Member FDIC, pursuant to license by Mastercard International
              Incorporated and may be used everywhere Debit Mastercard is
              accepted. Mastercard is a registered trademark, and the circle’s
              design is a trademark of Mastercard International Incorporated.
            </p>
            <p>
              Copyright ©2018 PEPS, a division of The Pay-O-Matic Corp. All
              Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
      <footer className={styles.mobileCta}>
        {/* <Button
          className={styles.mobileCtaButton}
          invert
          label="Download Mobile App"
        /> */}
        <Button
          href={payomaticLocationsLink}
          className={styles.mobileCtaButton}
          label="Get Started"
        />
      </footer>
    </Fragment>
  )
}
